<template>
  <span
    :class="compStyle"
  >
    {{ this.pointsChecker(value) }}
  </span>
</template>

<script>
export default {
  props: {
    value: Number,
    compStyle: String
  },

  methods: {
    /**
     * String value is checked and converted into number.
     */
    pointsChecker (value) {
      const checkedValue = value ?? 0
      const number = parseInt(checkedValue)

      return number
    }
  }
}
</script>
