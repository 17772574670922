<template>
  <account-layout>
    <page-loading v-if="!isLoaded"></page-loading>
    <account-menu />
    <div class="account-view">
      <div class="plugin-view-wrapper">
        <router-view class="plugin-view" v-if="isLoaded">
        </router-view>
      </div>
      <account-footer></account-footer>
    </div>
    <points-onboarding v-if="showOnboarding"></points-onboarding>
  </account-layout>
</template>

<script>
import PageLoading from '../partials/PageLoading'
import AccountMenu from '@/views/partials/AccountMenu.vue'
import AccountFooter from '../partials/AccountFooter.vue'
import PointsOnboarding from './onboarding/PointsOnboarding.vue'
import { mapGetters, mapActions } from 'vuex'
import Cookie from '@/core/services/Cookie'

export default {
  components: { AccountMenu, AccountFooter, PointsOnboarding, PageLoading },
  data () {
    return {
      isLoaded: false,
      showOnboarding: false
    }
  },
  computed: {
    ...mapGetters({
      userData: 'account/profileData'
    })
  },

  methods: {
    ...mapActions('account/', ['getUserData']),

    async loadAccountData () {
      const parameters = {
        headers: {
          accept: 'application/json',
          Authorization: this.getIDTokenFromCookies()
        }
      }

      try {
        await Promise.all([
          this.$store.dispatch('points/fetchPoints', parameters),
          this.getUserData()
        ])
      } catch (error) {
        this.error = error.message || 'Something went wrong during load Account data!'
      }

      if (!this.error) {
        this.showOnboarding = this.userData.profile.welcome_flag
        this.isLoaded = true
      } else {
        return this.$router.push({ name: 'points-errors' })
      }
    },

    getIDTokenFromCookies () {
      const box = Cookie.cookieBox()
      return box.idToken
    }

  },

  mounted () {
    this.loadAccountData()
  }
}
</script>

<style lang="sass" scoped>
.hero
  position: fixed
  z-index: 2
  top: 0
  right: 0
  left: 0
  bottom: 0
  padding-top: 97px;
.plugin-view-wrapper
  min-height: calc(100vh - 320px)
  @media screen and ( min-width: 768px)
    min-height: calc(100vh - 220px)
.account-view
  width: calc(100% - 287px)
  margin-left: 287px
  color: #363636
  background-color: #FBF7F7
  overflow: hidden
@media screen and ( max-width: 1023px)
  .account-view
    left: 0
    width: 100%
    margin: 12px 18px
    overflow-x: hidden
  .plugin-view
    height: auto
</style>
