<template>
  <div>
    <!-- Desktop Layout -->
    <onboarding-layout v-only-desktop v-if="showOnboarding">
      <!-- Onboarding A is shown only if extension is installed -->
      <onboarding-slider
        v-only-with-extension
        v-only-desktop
        :slider="slidesABVersion"
        type="a"
        @hide-onboarding="onHideOnboarding"
        data-testid="slider-a"
      ></onboarding-slider>

      <!-- Onboarding B is shown only If browser is not extension-fit -->
      <onboarding-slider
        v-hidden-extension-fit
        v-only-desktop
        :slider="slidesABVersion"
        type="b"
        @hide-onboarding="onHideOnboarding"
        data-testid="slider-b"
      ></onboarding-slider>

      <!-- Onboarding C is shown only extension is not installed and is extension-fit -->
      <onboarding-slider
        v-hidden-with-extension
        v-only-extension-fit
        v-only-desktop
        :slider="slidesCVersion"
        type="c"
        @hide-onboarding="onHideOnboarding"
        data-testid="slider-c"
      ></onboarding-slider>
    </onboarding-layout>

    <!-- Mobile Layout -->
    <onboarding-mobile-layout v-only-mobile v-if="showOnboarding">
      <onboarding-slider-mobile
        v-only-mobile
        @hide-onboarding="onHideOnboarding"
        data-testid="slider-mobile"
      />
    </onboarding-mobile-layout>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import OnboardingSlider from './OnboardingSlider.vue'
import OnboardingSliderMobile from './OnboardingSliderMobile.vue'

export default {
  components: { OnboardingSlider, OnboardingSliderMobile },

  data () {
    return {
      slidesABVersion: [
        {
          title: [['Congrats! You are all set & earned your first', 'black'], ['20 Pouch Points!', 'red']],
          des: 'Make sure you have downloaded the Pouch browser extension on Chrome, Firefox or Microsoft Edge (others are on the way!)',
          buttons: [['Continue to Pouch Points', 'skip'], ['Watch demo again', 'next']],
          image: 'slide-5b',
          contentImage: ''
        },
        // Not part of MVP:
        // {
        //   title: [['Stay', 'black'], ['updated', 'red']],
        //   des: 'Turn your notifications ON and never miss our best deals!',
        //   buttons: [['Next >', 'next'], ['Skip for now', 'skip']],
        //   image: '',
        //   contentImage: 'slide-4'
        // },
        {
          title: [['Redeem Pouch Points', 'black']],
          des: 'You can redeem your points in your account and get Gift Cards to spend at your favourite stores.',
          buttons: [['Next', 'next'], ['Skip for now', 'skip']],
          image: 'slide-3',
          contentImage: ''
        },
        {
          title: [['Collect  Pouch Points', 'black']],
          des: 'Earn Points on your qualifying purchases every time you use the Pouch browser extension. Learn more about <a target="_blank" href="/faq"> Pouch Points.</a>',
          buttons: [['Next', 'next'], ['Skip for now', 'skip']],
          image: 'slide-2',
          contentImage: ''
        },
        {
          title: [['Welcome to', 'black'], ['Pouch!', 'red']],
          des: 'We are happy to have you onboard. It’s time to find some great discounts together for your favourite shops!',
          buttons: [['Let’s start!', 'next'], ['Skip for now', 'skip']],
          image: 'slide-1',
          contentImage: ''
        }
      ],
      slidesCVersion: [
        {
          title: [['Add Pouch', 'red'], ['to your browser & get 100 Points', 'black']],
          des: 'Download the Pouch browser extension, login to your account & get 100 Pouch Points ( = 1£ )',
          buttons: [['Get Pouch - It’s free', 'install'], ['Watch demo again', 'next']],
          image: 'slide-5',
          contentImage: ''
        },
        // {
        //   title: [['Stay', 'black'], ['updated', 'red']],
        //   des: 'Turn your notifications ON and never miss our best deals!',
        //   buttons: [['Next >', 'next'], ['Skip for now', 'skip']],
        //   image: '',
        //   contentImage: 'slide-4'
        // },
        {
          title: [['Redeem Pouch Points', 'black']],
          des: 'You can redeem your points in your account and get Gift Cards to spend at your favourite stores.',
          buttons: [['Next', 'next'], ['Skip for now', 'skip']],
          image: 'slide-3',
          contentImage: ''
        },
        {
          title: [['Collect  Pouch Points', 'black']],
          des: 'Earn Points on your qualifying purchases every time you use the Pouch browser extension. Learn more about <a target="_blank" href="/faq"> Pouch Points.</a>',
          buttons: [['Next', 'next'], ['Skip for now', 'skip']],
          image: 'slide-2',
          contentImage: ''
        },
        {
          title: [['Welcome to', 'black'], ['Pouch!', 'red']],
          des: 'We are happy to have you onboard. It’s time to find some great discounts together for your favourite shops!',
          buttons: [['Let’s start!', 'next'], ['Skip for now', 'skip']],
          image: 'slide-1',
          contentImage: ''
        }
      ],
      showOnboarding: true
    }
  },

  computed: {
    ...mapGetters({
      user: 'account/profile',
      userData: 'account/profileData'
    })
  },

  methods: {
    ...mapActions('account/', ['updateUserData']),

    onHideOnboarding () {
      this.showOnboarding = false
      try {
        this.userData.profile = {
          ...this.userData.profile,
          ...{
            email: this.user.attributes.email,
            welcome_flag: false
          }
        }

        const parameters = this.userData

        this.updateUserData({ parameters })
      } catch (error) {
        console.log(error)
        return error
      }
    }
  }
}
</script>
