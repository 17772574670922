<template>
  <div
    v-only-mobile
    class="slide-small-device animated fadeIn"
  >
    <div
      id="slider-mobile"
      class="is-relative"
    >
      <div
        v-for="(slide, key) in slides"
        :key="key"
        :class="slide.hide ? 'is-hidden' : ''"
        class="
          card-special
          slide
          has-background-white
        "
      >
        <img
          class="image-medium"
          v-if="slide.image"
          v-lazy="`$/images/account/onboarding/${slide.image}.svg`"
        >

        <h1>
          <b
            :class="slide.title[0][1] === 'red' ? 'has-text-primary' : ''"
          >
            {{ slide.title[0][0] }}
          </b>
          <b
            v-if="slide.title[1]"
            :class="slide.title[1][1] === 'red' ? 'has-text-primary' : ''"
          >
            {{ slide.title[1][0] }}
          </b>
        </h1>

        <p v-html="slide.des"></p>

        <img
          class="image-large"
          v-if="slide.contentImage"
          v-lazy="`$/images/account/onboarding/${slide.contentImage}.svg`"
        >

        <div class="buttons-ctas">
          <!-- Progress bar -->
          <div
            class="progress"
            :style="`--value: ${slide.progress}%;`"
          >
          </div>

          <div
            class="is-flex has-text-centered has-background-white"
            v-if="slide.buttons[1]"
          >
            <!-- Primary button action -->
            <button
              class="button is-white is-text-special-gray"
              @click="skip"
            >
              {{ slide.buttons[0] }}
            </button>

            <!-- Secondary button action -->
            <button
              class="button is-primary"
              @click="next(key)"
            >
              {{ slide.buttons[1] }}
                  <span class="next--arrow" v-if="slide.buttons[1] === 'Next'"><i class="fas fa-chevron-right"></i> </span>
            </button>
          </div>

          <div
            class="has-text-centered has-background-white"
            v-else
          >
            <!-- Secondary button action -->
            <button
              class="button is-primary"
              @click="skip"
            >
              {{ slide.buttons[0] }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      index: 100,
      slides: [
        {
          title: [['Add Pouch', 'red'], ['to your browser when you’re on Desktop', 'black']],
          des: 'Psst don\'t forget in order to earn on your purchases download the extension when you\'re next on desktop and we will give you an extra 100 points!',
          buttons: ['Take me to my Points Overview'],
          nextSlide: 'pouch-points',
          image: 'slide-5',
          contentImage: '',
          progress: 100,
          hide: true
        },
        {
          title: [['Congrats! You are all set & earned', 'black'], ['20 Pouch Points!', 'red']],
          des: 'Let\'s head over to your points overview, where you can check your balance and find out how to earn more.',
          buttons: ['Skip for now', 'Next'],
          nextSlide: 'add',
          image: 'slide-5b',
          contentImage: '',
          progress: 80,
          hide: true
        },
        // Not part of MVP
        // {
        //   title: [['Stay', 'black'], ['updated', 'red']],
        //   des: 'Turn your notifications ON and never miss our best deals!',
        //   buttons: ['Skip for now', 'Next >'],
        //   nextSlide: 'congrats',
        //   image: '',
        //   contentImage: 'slide-4-mobile',
        //   progress: 66,
        //   hide: true
        // },
        {
          title: [['Redeem Pouch Points', 'black'], ['for Gift Cards!', 'red']],
          des: 'You can redeem your points in your account and get Gift Cards to spend at your favourite stores.',
          buttons: ['Skip for now', 'Next'],
          nextSlide: 'updated',
          image: 'slide-3',
          contentImage: '',
          progress: 50,
          hide: true
        },
        {
          title: [['Collect  Pouch Points', 'black']],
          des: 'Earn Points on your qualifying purchases every time you use the Pouch Browser Extension. Learn more about <a target="_blank" href="/faq"> Pouch Points.</a>',
          link: ['Learn more about ', 'Pouch Points'],
          buttons: ['Skip for now', 'Next'],
          nextSlide: 'redeem',
          image: 'slide-2',
          contentImage: '',
          progress: 33,
          hide: true
        },
        {
          title: [['Welcome to', 'black'], ['Pouch!', 'red']],
          des: 'We are happy to have you onboard. It’s time to find some great discounts together for your favourite shops!',
          buttons: ['Skip for now', 'Let’s start!'],
          nextSlide: 'collect',
          image: 'slide-1',
          contentImage: '',
          progress: 17,
          hide: false
        }
      ]
    }
  },

  methods: {
    next (key) {
      this.slides[key].hide = true
      this.slides[key - 1].hide = false
      const el = document.getElementById('slider-mobile').childNodes[key]
      this.index -= 1
      el.style.zIndex = this.index
      el.setAttribute('style', `z-index: ${this.index}`)
    },

    skip () {
      return this.$emit('hide-onboarding', true)
    }
  }
}
</script>
<style lang="sass" scoped>
.animated
  -webkit-animation-duration: 1.5s
  animation-duration: 1.5s
  -webkit-animation-fill-mode: both
  animation-fill-mode: both

@-webkit-keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1
@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1

#slider-mobile
  position: relative

.slide
  position: absolute
  z-index: 100

.fadeIn
  -webkit-animation-name: fadeIn
  animation-name: fadeIn

.slide-small-device
  padding-top: 12px
  padding-bottom: 200px
  @media (max-width: 600px)
    height: 700px
  @media (min-width: 600px)
    height: 1024px
.card-special
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  margin: 32px 24px
  padding: 32px 16px
  border-radius: 12px
h1
  font-size: 24px
  font-weight: 700
  line-height: 36px
  text-align: center
  margin-bottom: 12px
  div
    display: flex
p
  font-size: 16px
  line-height: 24px
  color: #7A7A7A
  text-align: center
  margin-bottom: 24px
.is-text-special-gray
  color: #7A7A7A
.close
  position: absolute
  right: 26px
  top: 10px
.buttons-ctas
  position: fixed
  bottom: 0
  width: 100%
  height: 65px
  .progress
    margin: 0
    width: var(--value)
    height: 4px
    background-color: #FF6441
    border-radius: 0 3px 3px 0
  button
    padding: 12.5px 32px
    margin: 12.5px auto
.next--arrow
  font-size: 14px
  margin-left: 5px
</style>
