<template>
  <div
    :id="`slider-${type}`"
    class="animated fadeIn"
  >
    <div
      class="
        slide
        card-special
        has-background-white
      "
      v-for="(slide, key) in slider"
      :key="key"
    >
      <div
        class="button close"
        v-on:click="skip"
      >
        <img
          v-lazy="`$/images/account/close.svg`"
          alt="Close button"
        >
      </div>

      <img
        class="image-medium"
        v-if="slide.image"
        v-lazy="`$/images/account/onboarding/${slide.image}.svg`"
      >

      <h1>
        <span
          :class="slide.title[0][1] === 'red' ? 'has-text-primary' : ''"
        >
          {{ slide.title[0][0] }}
        </span>

        <span v-if="slide.title[1]">
          <span
            :class="slide.title[1][1] === 'red' ? 'has-text-primary' : ''"
          >
            {{ slide.title[1][0] }}
          </span>
        </span>
      </h1>

      <p v-html="slide.des"></p>
      <img
        class="image-large"
        v-if="slide.contentImage"
        v-lazy="`$/images/account/onboarding/${slide.contentImage}.svg`"
      >

      <div>
        <span>
          <!-- Install button -->
          <add-to-browser
            v-if="slide.buttons[0][1] === 'install'"
            bold
            :enableInstallPopup="false"
            @click="$emit('close')"
            position="Onboarding"
          >
            {{ slide.buttons[0][0] }}
          </add-to-browser>

          <!-- General button action -->
          <p-button
            v-else
            primary
            @click="stepHanlder(key, slide.buttons[0][1])"
            :data-testid="slide.buttons[0][0]"
          >
            {{ slide.buttons[0][0] }}
            <span class="next--arrow" v-if="slide.buttons[0][0] === 'Next'"><i class="fas fa-chevron-right"></i> </span>
          </p-button>
        </span>

        <!-- Secondary button action -->
        <p-button
          white
          class="is-text-special-gray"
          @click="stepHanlder(key, slide.buttons[1][1])"
        >
          {{ slide.buttons[1][0] }}
        </p-button>
      </div>
    </div>
  </div>
</template>
<script>
import AddToBrowser from '@/views/partials/AddToBrowser'

export default {
  components: { AddToBrowser },

  props: {
    slider: Array,
    type: String
  },

  data () {
    return {
      index: 100
    }
  },
  methods: {
    stepHanlder (key, action) {
      if (action === 'next') return this.next(key)

      if (action === 'skip') return this.skip()
    },

    next (key) {
      const el = document.getElementById(`slider-${this.type}`).childNodes[key]
      this.index -= 1
      el.style.zIndex = this.index
      el.setAttribute('style', `z-index: ${this.index}`)
    },

    skip () {
      return this.$emit('hide-onboarding', true)
    }
  }
}
</script>
<style lang="sass" scoped>
.animated
  -webkit-animation-duration: 2s
  animation-duration: 2s
  -webkit-animation-fill-mode: both
  animation-fill-mode: both

@-webkit-keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1
@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1

.fadeIn
  -webkit-animation-name: fadeIn
  animation-name: fadeIn

#slider-a
  position: relative
  width: 660px
  height: 475px
#slider-b
  position: relative
  width: 660px
  height: 475px
#slider-c
  position: relative
  width: 660px
  height: 475px
.slide
  position: absolute
  z-index: 100
  box-shadow: rgba(255, 240, 240, 0.3) 0px 2px 8px 0px
.card-special
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  margin-top: 45px
  padding: 32px 32px
  width: 660px
  height: 475px
  border-radius: 12px
  background: #FFFFFF
h1
  font-size: 23px
  font-weight: 700
  line-height: 30px
  text-align: center
  margin-bottom: 12px
p
  padding-bottom: 32px
  font-size: 16px
  line-height: 24px
  color: #7A7A7A
  text-align: center
  margin-bottom: 24px
.is-text-special-gray
  color: #7A7A7A
.image-medium
  margin-top: 24px
  min-width: 165px
  min-height: 170px
.image-large
  min-width: 595px
  min-height: 265px
  padding-bottom: 24px
.close
  position: absolute
  right: 10px
  top: 20px
.next--arrow
  font-size: 14px
  margin-left: 5px
</style>
