<template>
  <div class="myaccount-view">
    <div class="profile-menu has-background-white">
      <div class="profile-info">
        <div class="profile-points">
          <img v-lazy="`$/images/account/profile-menu.svg`" class="profile-placeholder" alt="Profile">
          <div class="position-badge">
            <div class="points-badge">
              <span class="points has-text-white has-text-weight-semibold">

                <points-value
                  :value="this.accountPoints.total_active_points"
                />

              </span>
              <img v-lazy="`$/images/account/p-symbol.svg`" class="badge" />
            </div>
          </div>
        </div>
        <h1 v-if="profile">{{ profile.attributes.given_name }} {{ profile.attributes.family_name }}</h1>
      </div>

      <div class="plugins">
        <!-- Pouch Points -->
        <div
          :class="[currentPage.includes('pouch-points') ? 'selected' : '']"
          class="plugin plugin-hover"
        >
          <span class="icon icon-pouch"></span>
          <router-link
            v-t="`myaccount.pouch-points.title`"
            :to="'pouch-points/overview'"
            class="plugin-name"
          ></router-link>
        </div>

        <!-- COMING SOON: Favourite Brands -->
        <!-- <div
          :class="[currentPage.includes('favourite-brands') ? 'selected' : '']"
          class="plugin plugin-hover"
        >
          <span class="icon icon-star"></span>
          <router-link
            v-t="`myaccount.favourite-brands`"
            :to="{ name: 'favourite-brands'}"
            class="plugin-name"
          ></router-link>
        </div> -->

        <!-- My Profile -->
        <div
          :class="[currentPage.includes('profile') ? 'selected' : '']"
          class="plugin plugin-hover"
        >
          <span class="icon icon-profile"></span>
          <router-link
            v-t="`myaccount.myprofile`"
            :to="{ name: 'profile'}"
            class="plugin-name"
          ></router-link>
        </div>
        <!-- Notification Settings -->
        <div
          :class="[currentPage.includes('notification') ? 'selected' : '']"
          class="plugin plugin-hover"
        >
          <span class="icon icon-bell"></span>
          <router-link
            v-t="`myaccount.notification`"
            :to="{ name: 'notifications'}"
            class="plugin-name"
          ></router-link>
        </div>
        <!-- FAQ -->
        <div
          :class="[currentPage.includes('faq') ? 'selected' : '']"
          class="plugin plugin-hover"
        >
          <span class="icon icon-faq"></span>
          <router-link v-t="`myaccount.faq`"
            target="_blank"
            :to="{ name: 'faq'}"
            class="plugin-name"
          ></router-link>

        </div>
        <!-- Install Pouch -->
        <div class="plugin plugin-hover" v-only-extension-fit v-hidden-with-extension>
          <span class="icon icon-install"></span>
          <a v-t="`myaccount.install`"
            @click="redirectToStore()"
            class="plugin-name has-text-success"
          ></a>
        </div>
      </div>

      <div class="mobile-section" v-only-mobile>
        <router-link class="is-uppercase" :to="{ name: 'tandcs' }" v-t="`links.terms-and-conditions`"></router-link>
        <router-link class="is-uppercase" :to="{ name: 'privacypolicy' }" v-t="`links.privacy-policy`"></router-link>
        <router-link class="is-uppercase" :to="{ name: 'faq' }" v-t="`partials.profile-menu.help`"></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Uid from '@/core/services/Uid'
import Utm from '@/core/services/Utm'

import PointsValue from '../account/components/PointsValue'

export default {
  components: { PointsValue },

  computed: {
    currentPage () {
      return this.$route.path
    },

    ...mapGetters({
      profile: 'account/profile',
      accountPoints: 'points/accountPoints'
    })
  },

  methods: {
    /**
     * TODO: this method is repeted in the website and
     * a small service should be created. Until we know
     * when we can start working on the website debt, leaving as it is.
     */
    redirectToStore () {
      // Fire an event.
      this.$dispatcher.fire('app.addToBrowserClicked', { route: this.$route })

      // Convert `$browser.name` to corresponding config webstore key
      const storeKey = name => name === 'edge-chromium' ? 'edge' : name

      const url = [
        Utm.addQuery.bind(Utm),
        Uid.addQuery.bind(Uid)
      ].reduce(
        (carry, pipe) => pipe(carry),
        new URL(this.$config.extension.installationLinks[storeKey(this.$browser.name)])
      ).toString()

      // Redirect to the store in a new Tab.
      if (this.$browser.name === 'chrome') {
        window.open(
          url,
          'Install Pouch'
        ).focus()
      } else {
        window.open(url, '_blank').focus()
      }

      // TODO: tracking event to be triggered. Analytics will be confirmed in the future.

      // Bubble the click event up.
      this.$emit('click')
    }
  }
}
</script>

<style lang="sass" scoped>
.icon
  font-family: icomoon
  color: #ABABAB
  font-size: 18px
  margin-left: 50px;
.icon-bell
  &:before
    content: "\e900"
.icon-faq
  &:before
    content: "\e901"
.icon-install
  color: #158F0F
  &:before
    content: "\e902"
.icon-profile
  &:before
    content: "\e903"
.icon-pouch
  &:before
    content: "\e904"
.icon-star
  &:before
    content: "\e905"
.selected
  background-color: #fbf7f7
  .icon, a
    color: #ff6441
.myaccount-view
  position: relative
  @media screen and ( max-width: 1023px )
    display: none
  .profile-menu
    position: fixed
    overflow: hidden
    width: 287px
    height: 100%
    .profile-info
      display: flex
      justify-content: center
      align-items: center
      flex-direction: column
      margin-top: 52px
      .profile-points
        position: relative
        .profile-placeholder
          width: 120px
          height: 120px
        .position-badge
          position: absolute
          top: 28px
          right: -45px
          .points-badge
            display: flex
            width: 80px
            height: 80px
            flex-direction: column
            justify-content: center
            align-items: center
            background-image: url('/static/images/account/badge.svg')
            background-repeat: no-repeat
            background-size: 100%
            background-position: center
            .points
              margin-top: -8px
              font-size: 13px
            .badge
              width: 10px
              height: 10px
      h1
        margin-top: 16px
  .plugins
    margin-top: 21px
    padding-bottom: 15px
    width: 100%
    .plugin
      display: flex
      justify-content: flex-start
      align-items: center
      flex-direction: row
      padding: 13px 0
      width: 100%
      img
        margin-left: 50px
      a
        font-size: 16px
        color: gray
        margin-left: 14px
  .plugin-hover
    &:hover
      cursor: pointer
      .icon, a
        color: #ff6441 !important

  .mobile-section
    display: flex
    flex-direction: column
    margin-top: 10px
    a
      color: #7A7A7A
      text-decoration: none
      font-style: none
      padding: 13px 0 13px 50px
</style>
